<template>
  <div class="all">
    <AppHeader active-index="/hi"></AppHeader>
    <div class="main">
      <div :style="background" style="width: 100vw;height: 50vw;min-width: 960px;min-height: 480px;
      display: flex;align-items: center;">
        <div style="background: rgba(255,255,255,0.3);height: 28.125vw;width: 37.5vw;
        min-height:270px;min-width:360px;padding: 40px;margin-left: 10%">
          <div style="height:50%;display: flex;align-items: center;">
            <el-image :src="require('../../assets/App/hi9.png')" lazy style="height: 7vw;width:7vw;min-width:67px;
            min-height:67px;float: left"/>
            <div style="float: left;color: white;text-align: left;margin-left: 2%">
              <h1 style="line-height: 36px;">嗨科普</h1>
              <p style="font-weight: 500;line-height: 36px">用更有趣的方式科普知识</p>
            </div>
          </div>

          <div style="clear: both"></div>

          <div style="height: 50%;">
            <div style="float: left;">
              <!--              #229EC2-->
              <a href="https://apps.apple.com/cn/app/id1589575200">
                <!--              <div class="text" style="width:17vw;height:5vw;min-height: 48px;min-width: 163px;-->
                <!--              background:linear-gradient(to bottom right,#004e72,#229EC2);font-weight:550;border-radius: 4px;-->
                <!--              display:flex;justify-content:center;align-items:center;">即将上线，敬请期待！</div>-->
                <el-image class="opacity" :src="require('../../assets/App/3.png')" lazy
                          style="display: block;width:17vw;height:5vw;min-height: 48px;min-width: 163px"/>
              </a>
              <div style="height: 1vw;min-height: 9px"></div>
              <a href="https://app.oss.toodo.com.cn/apk/popularization.apk">
                <el-image class="opacity" :src="require('../../assets/App/4.png')" lazy
                          style="display: block;width:17vw;height:5vw;min-height: 48px;min-width: 163px"/>
              </a>
            </div>
            <el-image :src="require('../../assets/App/hi12.png')" lazy style="float: right;
                        width:11vw;height:11vw;min-height: 105px;min-width: 105px;background: #fff;padding: 6px;"/>
          </div>
        </div>
      </div>

      <div style="margin: 0 10%;text-align: left">
        <el-row :gutter="30" style="margin-top: 20px;">
          <el-col :span="12">
            <el-card shadow="hover"
                     style="min-height: 253px;border: 0">
              <div style="position: absolute;color: white;margin: 20px">
                <h2>航天科学</h2>
              </div>
              <img src="../../assets/App/hi5.jpg" style="height: 100%;width: 100%;display: block"/>
            </el-card>
          </el-col>
          <el-col :span="12">
            <el-card shadow="hover"
                     style="min-height: 253px;border: 0;">
              <div style="position: absolute;color: white;margin: 20px">
                <h2>地球与环境</h2>
              </div>
              <img src="../../assets/App/hi6.jpg" style="height: 100%;width: 100%;display: block"/>
            </el-card>
          </el-col>
        </el-row>

        <el-row :gutter="30" style="margin-top: 20px;margin-bottom: 20px">
          <el-col :span="12">
            <el-card shadow="hover"
                     style="min-height: 253px;border: 0">
              <div style="position: absolute;color: white;margin: 20px">
                <h2>生命科学</h2>
              </div>
              <img src="../../assets/App/hi7.jpg" style="height: 100%;width: 100%;display: block"/>
            </el-card>
          </el-col>
          <el-col :span="12">
            <el-card shadow="hover"
                     style="min-height: 253px;border: 0">
              <div style="position: absolute;color: white;margin: 20px">
                <h2>海洋科学</h2>
              </div>
              <img src="../../assets/App/hi8.jpg" style="height: 100%;width: 100%;display: block"/>
            </el-card>
          </el-col>
        </el-row>
      </div>

      <!--      <div class="divtest sdiv" data-animation="on">-->
      <div style="position: relative;text-align: left">
        <div style="position: absolute;top:50%;left:50%;transform: translate(-50%,-50%)">
          <div style="color: white;float: left;margin-left:-75%;">
            <div class="divtest sdiv" data-animation="on">
              <div
                  style="width: 80px;height: 30px;border: 1PX solid #fff;text-align: center;line-height: 30px;border-radius: 4px">
                精彩活动
              </div>
              <h1>我心中的科学家</h1>
              <h1 style="margin: 10px 0 30px 0">演讲比赛</h1>
              <div class="bigCircle">
                <div class="circle"></div>
              </div>
              <p style="margin-left:32px;font-weight: 500;line-height: 25px;text-align: left">
                视频内容讲述你心中的一位或者几位伟大科学家的事迹</p>
              <div class="bigCircle" style="margin:10px 0;">
                <div class="circle"></div>
              </div>
              <p style="margin:10px 0 10px 32px;font-weight: 500;line-height: 25px;text-align: left">
                视通过你了解科学家的生活轶事，科学贡献等角度开展</p>
              <div class="bigCircle">
                <div class="circle"></div>
              </div>
              <p style="margin-left:32px;font-weight: 500;line-height: 25px;text-align: left">视你心中的科学家哪些闪光点感染或者感触了你</p>
            </div>
          </div>
        </div>

        <img src="../../assets/App/hi2.jpg"
             style="width: 100vw;height: 50vw;min-height:480px;min-width:960px;display: block"/>
      </div>
      <!--      </div>-->

      <!--      <div class="divtest sdiv" data-animation="on">-->
      <div style="position: relative;text-align: left">
        <div style="position: absolute;top: 50%;transform: translate(-50%,-50%);left: 50%;">
          <div style="color: white;float: left;width: 50%;margin-left: 80%">
            <div class="divtest sdiv" data-animation="on">
              <div
                  style="width: 80px;height: 30px;border: 1PX solid #fff;text-align: center;line-height: 30px;border-radius: 4px">
                精彩活动
              </div>
              <h1>智能科技小能手</h1>
              <p style="margin-top:30px;font-weight: 500;line-height: 25px;text-align: left">
                活动旨在进一步提升青少年热爱科学、学习科学的热情，大力提升青少年“智能科技小能手”创新制造的良好氛围。航模大赛共分为手掷模型飞机竞赛、空气动力车直线障碍竞速赛、航空模型四轴飞行障碍赛三个竞赛项目。
              </p>
            </div>
          </div>
        </div>

        <img src="../../assets/App/hi3.jpg"
             style="width: 100vw;height: 50vw;min-height:480px;min-width:960px;display: block"/>
      </div>
      <!--      </div>-->

      <!--      <div class="divtest sdiv" data-animation="on">-->
      <div style="position: relative;text-align: left">
        <div style="position: absolute;color: white;top: 50%;transform: translateY(-50%);width: 30%;left: 10%">
          <div class="divtest sdiv" data-animation="on">
            <h1>更有趣的内容激发</h1>
            <h2 style="margin: 10px 0 30px 0;">青少年对科学的兴趣</h2>
            <p style="font-size: 26px;margin-bottom: 10px;">趣味：</p>
            <p style="font-weight: 500;line-height: 25px;text-align: left">
              以一颗童心去发现科学，去表达科学，比如一部电影，一幅漫画，以趣味的方式，以孩子们熟悉的方式去表达；
            </p>
            <p style="font-size: 26px;margin: 30px 0 10px 0;">好奇心：</p>
            <p style="font-weight: 500;line-height: 25px;text-align: left">
              科学是一个知识的海洋，其中充满了无数的秘密与惊喜，保持一颗好奇心，去探索，去寻找科学的乐趣
            </p>
          </div>
        </div>


        <img src="../../assets/App/hi4.jpg"
             style="width: 100vw;height: 50vw;min-height:480px;min-width:960px;display:block;"/>
      </div>
      <!--      </div>-->
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import AppHeader from "../../components/AppHeader";
import Footer from "../../components/Footer";

export default {
  name: "Hi",
  data() {
    return {
      input: null,
      background: {
        background: 'url(' + require('../../assets/App/hi1.jpg') + ')',
        backgroundSize: '100% 100%',
      },
    }
  },
  props: {
    activeIndex: {
      type: String
    }
  },
  methods: {},
  components: {
    AppHeader, Footer
  }
}
</script>

<style scoped>
>>> .el-card__body {
  padding: 0;
}

.opacity:active {
  opacity: 0.85;
}

.all {
  min-width: 960px;
}

ul {
  float: left;
}

li {
  font-size: 12px;
  list-style: none;
  margin-top: 20px;
}

a:link, a:visited {
  color: #ffffff;
}

.all {
  background: white;
  margin: 0;
  padding: 0;
}

.main {
  text-align: center;
  /*height: 2500px;*/
  margin: 60px 0 0 0;
  padding: 0;
  overflow: hidden;
}

.router-link-active {
  text-decoration: none;
}

a {
  text-decoration: none;
}

.circle {
  width: 12px;
  height: 12px;
  background-color: #fff;
  border-radius: 12px; /* 图形的半径 */
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.bigCircle {
  width: 22px;
  height: 22px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 22px; /* 图形的半径 */
  position: absolute;
}

.text {
  color: #efefef;
  position: relative;
  z-index: 1;
}

.text::before {
  content: "";
  z-index: -1;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #444444;
  transform-origin: center right;
  transform: scaleX(0);
  transition: transform 0.2s linear;
  /* 这里不要指明为 all */
}

.text:hover {
  cursor: pointer;
}

.text:hover::before {
  transform-origin: center left;
  transform: scaleX(1);
}
</style>